.leader-list-container {
  height: 570px;
  overflow-y: auto;
}

.leader-list {
  display: flex;
  flex-direction: column;
}

.leaderboard-header {
  flex-shrink: 0;
  border-radius: 5px;
  background: rgb(51, 12, 70);
  background: -moz-linear-gradient(0deg, rgba(51, 12, 70, 0.75) 0%, rgba(73, 23, 99, 0.75) 100%);
  background: -webkit-linear-gradient(0deg, rgba(51, 12, 70, 0.75) 0%, rgba(73, 23, 99, 0.75) 100%);
  background: linear-gradient(0deg, rgba(51, 12, 70, 0.75) 0%, rgba(73, 23, 99, 0.75) 100%);
  border: 1px solid #581f76;
  text-align: center;
  padding: 4px 10px;
  margin: 5px 5px 10px 5px;
  font-family: 'grand_hotelregular', cursive;
  color: #fff;
  font-size: 26px;
  font-weight: normal;
  flex-grow: 1;
}

.leader-list-holder {
  background: rgb(51, 12, 70);
  background: -moz-linear-gradient(0deg, rgba(51, 12, 70, 0.75) 0%, rgba(73, 23, 99, 0.75) 100%);
  background: -webkit-linear-gradient(0deg, rgba(51, 12, 70, 0.75) 0%, rgba(73, 23, 99, 0.75) 100%);
  background: linear-gradient(0deg, rgba(51, 12, 70, 0.75) 0%, rgba(73, 23, 99, 0.75) 100%);
  border: 1px solid #581f76;
  border-radius: 4px;
  margin: 2px;
}

.leader-level {
  color: #fff;
  margin: 0px 4px;
  padding: 4px 8px;
  border-radius: 4px;
  background: rgb(144, 53, 66);
  background: -moz-linear-gradient(0deg, rgba(144, 53, 66, 1) 0%, rgba(245, 123, 145, 1) 100%);
  background: -webkit-linear-gradient(0deg, rgba(144, 53, 66, 1) 0%, rgba(245, 123, 145, 1) 100%);
  background: linear-gradient(0deg, rgba(144, 53, 66, 1) 0%, rgba(245, 123, 145, 1) 100%);
}

.leader-list-item {
  display: flex;
  border-bottom: 1px solid #310945;
  margin: 2px 0;
  padding: 2px;
  align-items: center;
}

.leader-list-item:first-child {
  display: flex;
  border-bottom: none;
  margin: 2px 0 5px;
  padding: 2px;
  align-items: center;
  background: rgb(51, 12, 70);
  background: -moz-linear-gradient(0deg, rgba(51, 12, 70, 0.75) 0%, rgba(73, 23, 99, 0.75) 100%);
  background: -webkit-linear-gradient(0deg, rgba(51, 12, 70, 0.75) 0%, rgba(73, 23, 99, 0.75) 100%);
  background: linear-gradient(0deg, rgba(51, 12, 70, 0.75) 0%, rgba(73, 23, 99, 0.75) 100%);
  -webkit-box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.43);
  -moz-box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.43);
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.43);

}

.leader-list-item:first-child .avatar {
  width: 48px;
  height: 48px;
  margin: 6px 8px;
}


.leader-list-item .avatar {
  margin: 4px 5px;
  border: 1px solid #A669C6;
}


.leader-name {
  width: 250px;
  color: #fff;
  margin: 0px 4px 0 8px;
  font-size: 16px;
  font-weight: bold;
}

.leader-list-item:first-child .leader-name {
  width: 200px;
  font-size: 18px;
}

.leader-point {
  color: #fff;
  margin-right: 5px;
}

.leader-list-item:first-child .leader-point {
  font-size: 22px;
  font-weight: bold;
  margin-right: 10px;
}

.leader-trophy {
  height: 24px;
  margin: 0px 10px 0 5px;
  display: none;
}

.leader-list-item:first-child .leader-trophy {
  display: block;
}

.horizontalPanel {
  display: flex;
  margin-bottom: 4px;
}

/* Iphone 5 Screens  */

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .leader-list-item:first-child .leader-name {
    width: 160px;
    font-size: 18px;
  }

  .leader-list-item:first-child .avatar {
    width: 42px;
    height: 42px;
  }

  .leader-list-container {
    height: 520px;
  }
}

/* Iphone 6 Screens  */

@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
  .leader-list-item:first-child .leader-name {
    width: 180px;
    font-size: 18px;
  }

  .leader-list-item:first-child .avatar {
    width: 44px;
    height: 44px;
  }

  .leader-list-container {
    height: 540px;
  }
}

/* Iphone 6+ Screens  */

/* Portrait and Landscape */
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
  .leader-list-item:first-child .leader-name {
    width: 190px;
    font-size: 18px;
  }

  .leader-list-item:first-child .avatar {
    width: 44px;
    height: 44px;
  }

  .leader-list-container {
    height: 570px;
  }

}