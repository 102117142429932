.home-page {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-around;
}

.homePageLogo {
    align-self: center;
}

.play li {
    text-align: left;
}

.socialMedia {
    display: flex;
    justify-content: space-around;
}

.mainButtonContainer {
    display: flex;
    flex-direction: column;
}


/* Iphone 5 Screens  */

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
    .ui.modal>.header {
        font-size: 24px !important;
    }
    .play li {
        font-size: 13px;
    }
    .ui.modal>.content {
        padding: 2px;
    }
}