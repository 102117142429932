.pics-container {
    position: relative;
    display: flex;
}

.pics-container .expanded-pic {
    position: absolute;
    z-index: 2;
}

.pics-container .pics-row {
    display: flex;
    flex-direction: row;
}

.ui.image {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    background-color: transparent;
}

.ui.large.image {
    width: 450px;
    height: auto;
    font-size: 1.14285714rem;
    max-height: 400px;
}

.pics-container .expanded-pic {
    position: absolute;
    z-index: 2;
}

.transition {
    animation-iteration-count: 1;
    animation-duration: 0.3s;
    animation-timing-function: ease;
    animation-fill-mode: both;
}

.expanded-pic {
    border-radius: 10px;
    border: 2px solid #771d89;
}

.image-holder {
    width: 170px;
    height: 130px;
    margin: 5px 5px;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    border: 2px solid #771d89;
}


/* Iphone 5 Screens  */

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
    .image-holder {
        width: 150px;
        height: 110px;
        margin: 2px;
    }
}


/* Iphone 6 Screens  */

@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
    .image-holder {
        width: 170px;
        height: 120px;
        margin: 3px;
    }
}


/* Iphone 6+ Screens  */


/* Portrait and Landscape */

@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
    .image-holder {
        width: 180px;
        height: 150px;
        margin: 6px;
    }
}