.top-row-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.pics-and-grid {}

.puzzleLevel {
  color: #fff;
  margin: 0.5rem 0;
  text-transform: uppercase;
  text-align: center;
  border-radius: 5px;
  background: #7823a5;
  padding: 3px 5px;
  border: 1px solid #7823a5;
}

.puzzleLevelNumber {
  font-weight: bold;
}

/* Custom Button Style  */

.action-button {
  position: relative;
  padding: 10px 40px;
  margin: 0px 10px 10px 0px;
  float: left;
  border-radius: 10px;
  font-family: "Pacifico", cursive;
  font-size: 25px;
  color: #fff;
  text-decoration: none;
  transition: all 0.1s;
  -webkit-transition: all 0.1s;
}

.ui.modal>.actions {
  text-align: center !important;
  margin-bottom: 15px;
}

.ui.mini.modal {
  max-width: 250px;
}

.ui.modal>.header {
  background: rgb(144, 53, 66);
  background: -moz-linear-gradient(0deg,
      rgba(144, 53, 66, 1) 0%,
      rgba(245, 123, 145, 1) 100%);
  background: -webkit-linear-gradient(0deg,
      rgba(144, 53, 66, 1) 0%,
      rgba(245, 123, 145, 1) 100%);
  background: linear-gradient(0deg,
      rgba(144, 53, 66, 1) 0%,
      rgba(245, 123, 145, 1) 100%);
  font-family: "grand_hotelregular", cursive;
  color: #fff;
  font-size: 36px !important;
  padding: 5px 10px !important;
  text-align: center;
  line-height: 1em !important;
  width: 160px;
  margin-top: -19px;
  border-radius: 10px !important;
  border-bottom-width: 0;

  -webkit-box-shadow: 0px 5px 0px 0px rgba(87, 21, 31, 1);
  -moz-box-shadow: 0px 5px 0px 0px rgba(87, 21, 31, 1);
  box-shadow: 0px 5px 0px 0px rgba(87, 21, 31, 1);
}

.ui.modal>.actions {
  background: transparent;
}

.ui.modal {
  background: rgb(253, 201, 253);
  background: -moz-linear-gradient(0deg,
      rgba(253, 201, 253, 1) 0%,
      rgba(253, 237, 253, 1) 100%);
  background: -webkit-linear-gradient(0deg,
      rgba(253, 201, 253, 1) 0%,
      rgba(253, 237, 253, 1) 100%);
  background: linear-gradient(0deg,
      rgba(253, 201, 253, 1) 0%,
      rgba(253, 237, 253, 1) 100%);

  display: flex !important;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
}

.ui.modal>.content {
  background: transparent;
  color: #356f8d;
  font-size: 16px;
  text-align: center;
  padding: 15px 10px 10px 10px;
}

.ui.modal>.actions {
  border-top: none;
  padding: 5px 10px;
}

.home-and-hint {
  display: flex;
  justify-content: space-between;
}