* {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


/* Loading Custom Fonts  */

@font-face {
    font-family: "grand_hotelregular";
    src: url("/fonts/grandhotel-regular-webfont.woff2") format("woff2"), url("/fonts/grandhotel-regular-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

a,
.highlight {
    color: #CE5B70;
    transition: .3s;
    font-weight: bold;
}

a:hover {
    color: #983644;
}

#root {
    background: -moz-linear-gradient(0deg, rgba(36, 5, 41, 1) 0%, rgba(69, 8, 80, 1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(36, 5, 41, 1) 0%, rgba(69, 8, 80, 1) 100%);
    background: linear-gradient(0deg, rgba(36, 5, 41, 1) 0%, rgba(69, 8, 80, 1) 100%);
}

.main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-image: url("/images/padam-bg.png");
    background-position: center;
}

.game-container {
    /* background-image: url("/images/background.png");
  background-repeat: no-repeat;
  background-position: center; */
    background: -moz-linear-gradient(0deg, rgba(36, 5, 41, 0.7) 0%, rgba(69, 8, 80, 1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(36, 5, 41, 0.7) 0%, rgba(69, 8, 80, 1) 100%);
    background: linear-gradient(0deg, rgba(36, 5, 41, 0.7) 0%, rgba(69, 8, 80, 1) 100%);
    border: 1px solid #581f76;
    border-radius: 4px;
    padding: 5px;
    min-width: 300px;
    min-height: 500px;
    position: relative;
}

button {
    border-radius: 5px;
    background: linear-gradient(#52b2e2 0%, #3e84a7 100%);
    border: none;
    padding: 5px 16px;
    color: #fff;
    margin: 5px;
    font-size: 24px;
    font-family: "grand_hotelregular", cursive;
    transition: 0.3s;
    -webkit-box-shadow: 0px 5px 0px 0px rgba(11, 65, 105, 1);
    -moz-box-shadow: 0px 5px 0px 0px rgba(11, 65, 105, 1);
    box-shadow: 0px 5px 0px 0px rgba(11, 65, 105, 1);
}

button:focus {
    outline: none;
}

button.critical {
    background: rgb(144, 53, 66);
    background: -moz-linear-gradient(0deg, rgba(144, 53, 66, 1) 0%, rgba(245, 123, 145, 1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(144, 53, 66, 1) 0%, rgba(245, 123, 145, 1) 100%);
    background: linear-gradient(0deg, rgba(144, 53, 66, 1) 0%, rgba(245, 123, 145, 1) 100%);
    -webkit-box-shadow: 0px 5px 0px 0px rgba(87, 21, 31, 1);
    -moz-box-shadow: 0px 5px 0px 0px rgba(87, 21, 31, 1);
    box-shadow: 0px 5px 0px 0px rgba(87, 21, 31, 1);
    transition: 0.3s;
}

button.critical:active {
    background: rgb(95, 29, 39);
    background: -moz-linear-gradient(0deg, rgba(95, 29, 39, 1) 0%, rgba(142, 57, 71, 1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(95, 29, 39, 1) 0%, rgba(142, 57, 71, 1) 100%);
    background: linear-gradient(0deg, rgba(95, 29, 39, 1) 0%, rgba(142, 57, 71, 1) 100%);
    -webkit-box-shadow: 0px 5px 0px 0px rgba(152, 35, 56, 1);
    -moz-box-shadow: 0px 5px 0px 0px rgba(152, 35, 56, 1);
    box-shadow: 0px 5px 0px 0px rgba(152, 35, 56, 1);
}

button.loading:after {
    position: absolute;
    content: '';
    top: calc(50% - .5em);
    left: calc(50% - .5em);
    width: 1em;
    height: 1em;
    animation: button-spin .6s linear;
    animation-iteration-count: infinite;
    border-radius: 500rem;
    border-color: #ab43e2 transparent transparent;
    border-style: solid;
    border-width: .2em;
    box-shadow: 0 0 0 1px transparent;
}

button.loading:before {
    position: absolute;
    content: '';
    top: calc(50% - .5em);
    left: calc(50% - .5em);
    width: 1em;
    height: 1em;
    border-radius: 500rem;
    border: .2em solid rgb(89, 7, 101);
}

button.loading {
    position: relative;
    cursor: default;
    text-shadow: none !important;
    color: transparent !important;
    opacity: 1;
    pointer-events: auto;
    transition: all 0s linear, opacity .1s ease;
}

.hintButton {
    border-radius: 5px;
    background: none;
    border: none;
    padding: 2px;
    color: #fff;
    font-size: 14px;
    transition: 0.3s;
    box-shadow: none;
    margin: 0;
    font-family: Lato, Arial, Helvetica, sans-serif;
}

.hintButton .iconHolder {
    border-color: #b4d9f9;
    background: rgb(20, 78, 108);
    background: -moz-linear-gradient(0deg, rgba(20, 78, 108, 1) 0%, rgba(108, 183, 222, 1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(20, 78, 108, 1) 0%, rgba(108, 183, 222, 1) 100%);
    background: linear-gradient(0deg, rgba(20, 78, 108, 1) 0%, rgba(108, 183, 222, 1) 100%);
    -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, .6);
    -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, .6);
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, .6);
}

.hintButton.mute .iconHolder {
    border-color: #e6c46e;
    background: rgb(191, 144, 16);
    background: -moz-linear-gradient(0deg, rgba(191, 144, 16, 1) 0%, rgba(234, 212, 93, 1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(191, 144, 16, 1) 0%, rgba(234, 212, 93, 1) 100%);
    background: linear-gradient(0deg, rgba(191, 144, 16, 1) 0%, rgba(234, 212, 93, 1) 100%);
}

.hintButton .value {
    background: #11638b;
    border-color: #2d8cdd;
}

button:hover {
    opacity: 0.7;
}

.ui.dimmer {
    background-color: #2b0633ab !important;
}


/* Loading Dimmer styles  */

.blurring.dimmable>.inverted.dimmer {
    background-color: #2b0633ab !important;
}

.ui.inverted.dimmer .ui.loader:after {
    border-color: #ab43e2 transparent transparent !important;
}

.ui.inverted.dimmer .ui.loader:before {
    border-color: rgb(127, 23, 142);
}

.ui.inverted.dimmer .ui.loader,
.ui.loader {
    color: #fff;
}

.messageBox {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.gameEnd {
    max-width: 350px;
    margin-top: 35px;
}

.lastPage {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-around;
}

.message {
    font-size: 18px;
    color: #fff;
    padding: 10px;
}

.messageBox h3 {
    background: rgb(144, 53, 66);
    background: -moz-linear-gradient(0deg, rgba(144, 53, 66, 1) 0%, rgba(245, 123, 145, 1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(144, 53, 66, 1) 0%, rgba(245, 123, 145, 1) 100%);
    background: linear-gradient(0deg, rgba(144, 53, 66, 1) 0%, rgba(245, 123, 145, 1) 100%);
    font-family: "grand_hotelregular", cursive;
    color: #fff;
    font-size: 24px !important;
    padding: 5px 10px !important;
    text-align: center;
    width: 160px;
    margin-top: -19px;
    border-radius: 10px !important;
    border-bottom-width: 0;
    -webkit-box-shadow: 0px 5px 0px 0px rgba(87, 21, 31, 1);
    -moz-box-shadow: 0px 5px 0px 0px rgba(87, 21, 31, 1);
    box-shadow: 0px 5px 0px 0px rgba(87, 21, 31, 1);
}


/* Loading Indicator CSS Style  */

.loadingIndicator {
    width: 2em;
    height: 2em;
    border-radius: 50%;
    border: 4px solid silver;
    animation: circ-anim 0.7s linear infinite;
    border-bottom-color: grey;
    position: absolute;
    top: calc(50% - 1em);
    left: calc(50% - 1em);
}

@keyframes circ-anim {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}


/* Animations */

@-webkit-keyframes jello {
    from,
    11.1%,
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    22.2% {
        -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
        transform: skewX(-12.5deg) skewY(-12.5deg);
    }
    33.3% {
        -webkit-transform: skewX(6.25deg) skewY(6.25deg);
        transform: skewX(6.25deg) skewY(6.25deg);
    }
    44.4% {
        -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
        transform: skewX(-3.125deg) skewY(-3.125deg);
    }
    55.5% {
        -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
        transform: skewX(1.5625deg) skewY(1.5625deg);
    }
    66.6% {
        -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
        transform: skewX(-0.78125deg) skewY(-0.78125deg);
    }
    77.7% {
        -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
        transform: skewX(0.390625deg) skewY(0.390625deg);
    }
    88.8% {
        -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
        transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    }
}

@keyframes jello {
    from,
    11.1%,
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    22.2% {
        -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
        transform: skewX(-12.5deg) skewY(-12.5deg);
    }
    33.3% {
        -webkit-transform: skewX(6.25deg) skewY(6.25deg);
        transform: skewX(6.25deg) skewY(6.25deg);
    }
    44.4% {
        -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
        transform: skewX(-3.125deg) skewY(-3.125deg);
    }
    55.5% {
        -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
        transform: skewX(1.5625deg) skewY(1.5625deg);
    }
    66.6% {
        -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
        transform: skewX(-0.78125deg) skewY(-0.78125deg);
    }
    77.7% {
        -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
        transform: skewX(0.390625deg) skewY(0.390625deg);
    }
    88.8% {
        -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
        transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    }
}

.jello {
    -webkit-animation-name: jello;
    animation-name: jello;
    -webkit-transform-origin: center;
    transform-origin: center;
    animation-duration: 1s;
    animation-delay: 1s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
}

@-webkit-keyframes bounceIn {
    from,
    20%,
    40%,
    60%,
    80%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }
    20% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1);
    }
    40% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9);
        transform: scale3d(0.9, 0.9, 0.9);
    }
    60% {
        opacity: 1;
        -webkit-transform: scale3d(1.03, 1.03, 1.03);
        transform: scale3d(1.03, 1.03, 1.03);
    }
    80% {
        -webkit-transform: scale3d(0.97, 0.97, 0.97);
        transform: scale3d(0.97, 0.97, 0.97);
    }
    to {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@keyframes bounceIn {
    from,
    20%,
    40%,
    60%,
    80%,
    to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }
    20% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1);
    }
    40% {
        -webkit-transform: scale3d(0.9, 0.9, 0.9);
        transform: scale3d(0.9, 0.9, 0.9);
    }
    60% {
        opacity: 1;
        -webkit-transform: scale3d(1.03, 1.03, 1.03);
        transform: scale3d(1.03, 1.03, 1.03);
    }
    80% {
        -webkit-transform: scale3d(0.97, 0.97, 0.97);
        transform: scale3d(0.97, 0.97, 0.97);
    }
    to {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

.bounceIn {
    -webkit-animation-duration: 0.75s;
    animation-duration: 0.75s;
    -webkit-animation-name: bounceIn;
    animation-name: bounceIn;
    -webkit-transform-origin: center;
    transform-origin: center;
    animation-delay: 1s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
}

@-webkit-keyframes wobble {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    15% {
        -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
        transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    }
    30% {
        -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
        transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    }
    45% {
        -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
        transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    }
    60% {
        -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
        transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    }
    75% {
        -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
        transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes wobble {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    15% {
        -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
        transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    }
    30% {
        -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
        transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    }
    45% {
        -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
        transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    }
    60% {
        -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
        transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    }
    75% {
        -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
        transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.wobble {
    -webkit-animation-name: wobble;
    animation-name: wobble;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-iteration-count: 2;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
    .game-container {
        min-width: 250px;
        min-height: 400px;
    }
}


/* Icon button alignment override  */

i.icon {
    margin: 0 !important;
}

.autoClosePopup {
    position: absolute;
    top: 30%;
    /* left: calc(50%-75px); */
}