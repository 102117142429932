.cell {
    height: 38px;
    width: 40px;
    margin: 5px;
    border-radius: 4px;
    font-size: 1.8rem;
    font-weight: 600;
    text-transform: uppercase;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    /* Applying Gradient Backgrounds  */
    background: rgb(144, 53, 66);
    background: -moz-linear-gradient(0deg, rgba(144, 53, 66, 1) 0%, rgba(245, 123, 145, 1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(144, 53, 66, 1) 0%, rgba(245, 123, 145, 1) 100%);
    background: linear-gradient(0deg, rgba(144, 53, 66, 1) 0%, rgba(245, 123, 145, 1) 100%);
    /* Disabling Text seleciton on Buttons  */
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    /* Box Dropshadow  */
    -webkit-box-shadow: 0px 5px 0px 0px rgba(87, 21, 31, 1);
    -moz-box-shadow: 0px 5px 0px 0px rgba(87, 21, 31, 1);
    box-shadow: 0px 5px 0px 0px rgba(87, 21, 31, 1);
    transition: 0.3s;
}

.cell:hover {
    opacity: 0.7;
}

.cell:active {
    background: rgb(95, 29, 39);
    background: -moz-linear-gradient(0deg, rgba(95, 29, 39, 1) 0%, rgba(142, 57, 71, 1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(95, 29, 39, 1) 0%, rgba(142, 57, 71, 1) 100%);
    background: linear-gradient(0deg, rgba(95, 29, 39, 1) 0%, rgba(142, 57, 71, 1) 100%);
    -webkit-box-shadow: 0px 5px 0px 0px rgba(152, 35, 56, 1);
    -moz-box-shadow: 0px 5px 0px 0px rgba(152, 35, 56, 1);
    box-shadow: 0px 5px 0px 0px rgba(152, 35, 56, 1);
}

.answer-grid .cell.inactive {
    background: rgb(40, 100, 131);
    background: -moz-linear-gradient(0deg, rgba(40, 100, 131, 1) 0%, rgba(82, 178, 226, 1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(40, 100, 131, 1) 0%, rgba(82, 178, 226, 1) 100%);
    background: linear-gradient(0deg, rgba(40, 100, 131, 1) 0%, rgba(82, 178, 226, 1) 100%);
    -webkit-box-shadow: 0px 5px 0px 0px rgba(11, 65, 105, 1);
    -moz-box-shadow: 0px 5px 0px 0px rgba(11, 65, 105, 1);
    box-shadow: 0px 5px 0px 0px rgba(11, 65, 105, 1);
}

.hints-row .cell.inactive {
    background: rgb(92, 124, 141);
    background: -moz-linear-gradient(0deg, rgba(92, 124, 141, 1) 0%, rgba(166, 200, 217, 1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(92, 124, 141, 1) 0%, rgba(166, 200, 217, 1) 100%);
    background: linear-gradient(0deg, rgba(92, 124, 141, 1) 0%, rgba(166, 200, 217, 1) 100%);
    -webkit-box-shadow: 0px 5px 0px 0px rgba(70, 92, 110, 1);
    -moz-box-shadow: 0px 5px 0px 0px rgba(70, 92, 110, 1);
    box-shadow: 0px 5px 0px 0px rgba(70, 92, 110, 1);
}

.cell.inactive:hover {
    opacity: 1;
}

.cell.is-hint {
    background: rgb(15, 98, 34);
    background: -moz-linear-gradient(0deg, rgba(15, 98, 34, 1) 0%, rgba(82, 226, 126, 1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(15, 98, 34, 1) 0%, rgba(82, 226, 126, 1) 100%);
    background: linear-gradient(0deg, rgba(15, 98, 34, 1) 0%, rgba(82, 226, 126, 1) 100%);
    -webkit-box-shadow: 0px 5px 0px 0px rgba(11, 72, 25, 1);
    -moz-box-shadow: 0px 5px 0px 0px rgba(11, 72, 25, 1);
    box-shadow: 0px 5px 0px 0px rgba(11, 72, 25, 1);
}

.play-grid {}

.answer-grid {
    display: flex;
    flex-wrap: wrap;
    max-width: 350px;
    justify-content: center;
    margin: 5px;
    /* Temporary */
}

.answer-grid .answer-row {
    display: flex;
}

.cell.space {
    font-size: 10px;
    background: rgb(142, 127, 11);
    background: -moz-linear-gradient(0deg, rgba(142, 127, 11, 1) 0%, rgba(253, 241, 145, 1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(142, 127, 11, 1) 0%, rgba(253, 241, 145, 1) 100%);
    background: linear-gradient(0deg, rgba(142, 127, 11, 1) 0%, rgba(253, 241, 145, 1) 100%);
    -webkit-box-shadow: 0px 5px 0px 0px rgba(82, 72, 0, 1);
    -moz-box-shadow: 0px 5px 0px 0px rgba(82, 72, 0, 1);
    box-shadow: 0px 5px 0px 0px rgba(82, 72, 0, 1);
    color: #000000;
}

.hints-row {
    /* justify-content: center; */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px;
}

.hints {
    display: flex;
}

.result-section {
    display: flex;
    justify-content: center;
}

.iconButton {
    display: flex;
    position: relative;
    align-items: center;
}

.statistic {
    justify-content: center;
    align-items: center;
    display: flex;
}

.value {
    border-radius: 5px;
    background: #7823a5;
    padding: 3px 5px 3px 15px;
    margin-left: -11px;
    color: #fff;
    font-weight: bold;
    border: 1px solid #7823a5;
}

.iconHolder {
    background: #650f76;
    border: 2px solid #7823a5;
    width: 32px;
    height: 32px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    position: relative;
}

.result-section {
    transition: 0.3s;
}

.verification-error {
    display: flex;
    align-items: center;
}

.verification-error p {
    margin: 0px 0 2px 5px;
    font-size: 18px;
}

.verification-error.label {
    padding: 5px;
    background: rgb(163, 20, 40);
    background: -moz-linear-gradient(0deg, rgba(163, 20, 40, 1) 0%, rgba(235, 14, 54, 1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(163, 20, 40, 1) 0%, rgba(235, 14, 54, 1) 100%);
    background: linear-gradient(0deg, rgba(163, 20, 40, 1) 0%, rgba(235, 14, 54, 1) 100%);
    color: white;
    border-radius: 4px;
    font-weight: 600;
}

.verification-error i.inverted.circular.yellow.icon {
    color: #000000 !important;
}


/* Iphone 5 Screens  */

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
    .cell {
        height: 32px;
        width: 30px;
        margin: 3px 3px;
    }
}


/* Iphone 6 Screens  */

@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
    .cell {
        height: 36px;
        width: 36px;
        margin: 5px 3px;
    }
}


/* Iphone 6+ Screens  */


/* Portrait and Landscape */

@media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
    .cell {
        height: 38px;
        width: 40px;
        margin: 5px 3px;
    }
}